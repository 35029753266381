import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  colors: {
    brand: {
      red: "#f56565",
      gray: "#a0aec0",
    },
  },
  fonts: {
    body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    heading:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  },
  sizes: {
    max: "max-content",
    min: "min-content",
    full: "100%",
  },
  components: {
    Button: {
      baseStyle: {
        rounded: "full",
      },
      sizes: {
        sm: {
          h: "32px",
          fontSize: { base: "xs", md: "sm" },
        },
      },
      variants: {
        solid: {
          bg: "brand.red",
          _hover: {
            bg: "red.500",
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: 600,
        lineHeight: "110%",
      },
      sizes: {
        xl: {
          fontSize: { base: "3xl", sm: "4xl", md: "8xl" },
        },
      },
    },
    Text: {
      baseStyle: {
        color: "brand.gray",
        maxW: "3xl",
      },
    },
  },
});

export default theme;
