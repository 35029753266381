import React from "react";
import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
  useTheme,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Link,
  Image,
} from "@chakra-ui/react";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";

export const Fin = () => {
  const theme = useTheme();

  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        minH="70vh"
      >
        <Container maxW={"5xl"}>
          <Stack
            textAlign={"center"}
            align={"center"}
            spacing={{ base: theme.sizes[8], md: theme.sizes[10] }}
            py={{ base: theme.sizes[20], md: theme.sizes[28] }}
          >
            <Heading as="h1" fontSize={{ base: "4xl", sm: "6xl", md: "8xl" }}>
              Thanks for visiting{" "}
              <Text as={"span"} color={"brand.red"}>
                {`:)`}
              </Text>
            </Heading>
            <Image
              src="pray.png"
              maxH={{
                base: "150px",
                md: "300px",
              }}
            />
            <Text
              fontSize={{
                base: "md",
                sm: "lg",
                md: "xl",
              }}
            >
              Feel free to reach out to me at any time!
            </Text>

            <Stack spacing={6} direction={"row"}>
              <Popover>
                <PopoverTrigger>
                  <div>
                    <Button px={6} bgColor="gray.400">
                      Contact me
                    </Button>{" "}
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverHeader>
                    I look forward to hearing from you!
                  </PopoverHeader>
                  <PopoverBody>
                    <Flex alignItems="center" mb="2">
                      <EmailIcon boxSize="5" mr="2" />
                      <Link
                        href="mailto:1marc.ghannam@gmail.com"
                        color="brand.red"
                      >
                        1marc.ghannam@gmail.com
                      </Link>
                    </Flex>
                    <Flex alignItems="center">
                      <PhoneIcon boxSize="5" mr="2" />
                      <Link href="tel:+17815322591" color="brand.red">
                        +1 (781) 532 - 2591
                      </Link>
                    </Flex>{" "}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Stack>
          </Stack>
        </Container>
      </Flex>
    </>
  );
};
