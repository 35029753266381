import {
  Box,
  Heading,
  Text,
  Stack,
  Image,
  AspectRatio,
} from "@chakra-ui/react";

export const ProjectCard = ({
  title,
  company,
  description,
  image,
  color,
  date,
  width,
  height,
}) => {
  return (
    <Box
      width={width}
      boxShadow={"2xl"}
      rounded={"md"}
      p={6}
      overflow={"hidden"}
    >
      <Box
        h={"210px"}
        bg={"gray.100"}
        mt={-6}
        mx={-6}
        mb={6}
        pos={"relative"}
        overflow={"hidden"}
      >
        <AspectRatio
          ratio={4 / 3}
          w={"100%"}
          h={"100%"}
          pos={"absolute"}
          top={"0"}
          left={"0"}
          right={"0"}
          bottom={"0"}
        >
          <Image src={image} height={"220"} width={width} />
        </AspectRatio>
      </Box>
      <Stack
        height={{
          base: "60",
          md: "60",
        }}
      >
        <Heading fontSize={"2xl"} fontFamily={"body"}>
          {title}
        </Heading>
        <Text
          color={color}
          textTransform={"uppercase"}
          fontWeight={800}
          fontSize={"sm"}
          letterSpacing={1.1}
        >
          {company}
        </Text>
        <Text color={"gray.500"}>{description}</Text>
      </Stack>
      <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
        <Stack direction={"column"} spacing={0} fontSize={"sm"}>
          <Text color={"gray.500"}>{date}</Text>
        </Stack>
      </Stack>
    </Box>
  );
};
