import React from "react";
import {
  Box,
  chakra,
  Container,
  Text,
  HStack,
  VStack,
  Flex,
  useColorModeValue,
  useBreakpointValue,
  Image,
  ModalOverlay,
  useDisclosure,
  Badge,
} from "@chakra-ui/react";

const milestones = [
  {
    id: `1`,
    date: "August 2021 - Present",
    company: "LinkPool",
    title: "Full Stack Engineer",
    description: ` I steer the full development cycle of scalable solutions that bridge the gap between smart contracts and off-chain computation.`,
    logo: "linkpool.png",
    tasks: [
      "At LinkPool, a Chainlink Partner and Service Provider, I've served as a Blockchain Engineer since August 2021. My role involves building adapters in TypeScript, with the help of ethers.js and Chainlink's libraries, to enable Data Providers to bring their data onto the blockchain. I actively integrate real-world data, such as sports data and price feeds, into web3 applications. One of my significant accomplishments was constructing the first permissionless sports betting dApp on Optimism, known as Overtime Market. My responsibilities also include creating consumer smart contracts in Solidity to test adapter functionalities on-chain, managing Chainlink nodes and ensuring the uptime of data feeds, and offering consultancy to companies interested in or currently utilizing Chainlink's technology. Furthermore, I conduct tests on adapters using Jest and Nock, and build a library of smart contracts and an SDK to help onboard developers and companies to Chainlink's automation contracts.",
    ],
    badges: ["Solidity", "TypeScript", "Chainlink", "Node.js", "React"],
  },
  {
    id: 2,
    date: "March 2020 - August 2021",
    company: "TireTutor",
    title: "Software Engineer",
    description: `I spearheaded several key initiatives including the creation of an e-commerce shopping cart and development of a CRM system.

    `,
    logo: "tiretutor.png",
    tasks: [
      "At LinkPool, a Chainlink Partner and Service Provider, I've served as a Blockchain Engineer since August 2021. My role involves building adapters in TypeScript, with the help of ethers.js and Chainlink's libraries, to enable Data Providers to bring their data onto the blockchain. I actively integrate real-world data, such as sports data and price feeds, into web3 applications. One of my significant accomplishments was constructing the first permissionless sports betting dApp on Optimism, known as Overtime Market. My responsibilities also include creating consumer smart contracts in Solidity to test adapter functionalities on-chain, managing Chainlink nodes and ensuring the uptime of data feeds, and offering consultancy to companies interested in or currently utilizing Chainlink's technology. Furthermore, I conduct tests on adapters using Jest and Nock, and build a library of smart contracts and an SDK to help onboard developers and companies to Chainlink's automation contracts.",
    ],
    badges: ["Next.js", "Django", "Redux", "Cypress", "Figma"],
  },
  {
    id: 3,
    date: "May 2019 - December 2020",
    company: "Analysis Group",
    title: "Support Engineer",
    description: `I handled software configuration, process documentation, and provided technical guidance.`,
    logo: "analysis.png",
    tasks: [
      "At LinkPool, a Chainlink Partner and Service Provider, I've served as a Blockchain Engineer since August 2021. My role involves building adapters in TypeScript, with the help of ethers.js and Chainlink's libraries, to enable Data Providers to bring their data onto the blockchain. I actively integrate real-world data, such as sports data and price feeds, into web3 applications. One of my significant accomplishments was constructing the first permissionless sports betting dApp on Optimism, known as Overtime Market. My responsibilities also include creating consumer smart contracts in Solidity to test adapter functionalities on-chain, managing Chainlink nodes and ensuring the uptime of data feeds, and offering consultancy to companies interested in or currently utilizing Chainlink's technology. Furthermore, I conduct tests on adapters using Jest and Nock, and build a library of smart contracts and an SDK to help onboard developers and companies to Chainlink's automation contracts.",
    ],
    badges: ["Python", "SQL", "Tableau", "Power BI", "Excel"],
  },
  {
    id: 4,
    date: "September 2017 - August 2021",
    company: "Wentworth Institute of Technology",
    title: "Computer Engineering",
    description: `I graduated with a Bachelor of Science in Computer Engineering and a minor in Internet of Things.`,
    logo: "wentworth.png",
    tasks: [],
    badges: ["C", "C++", "Java", "Python", "Assembly"],
  },
];

export const WorkExperience = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <Container
      maxWidth={{
        base: "container.sm",
        md: "container.md",
        lg: "container.lg",
      }}
      p={{ base: 2, sm: 10 }}
      minH="100vh"
      overflow="hidden"
    >
      <chakra.h3
        fontSize={{ base: "2xl", sm: "3xl", md: "6xl" }}
        fontWeight="bold"
        textAlign="center"
        mb={{ base: 20, md: 60 }}
      >
        My Journey
      </chakra.h3>
      {milestones.map((milestone) => (
        <Flex key={milestone.id} mb="10px">
          {/* Desktop view(left card) */}
          {isDesktop && milestone.id % 2 === 0 && (
            <>
              <EmptyCard />
              <LineWithDot />
              <Card {...milestone} />
            </>
          )}

          {/* Mobile view */}
          {isMobile && (
            <>
              <LineWithDot />
              <Card {...milestone} />
            </>
          )}

          {/* Desktop view(right card) */}
          {isDesktop && milestone.id % 2 !== 0 && (
            <>
              <Card {...milestone} />

              <LineWithDot />
              <EmptyCard />
            </>
          )}
        </Flex>
      ))}
    </Container>
  );
};

const Card = ({
  id,
  title,
  description,
  date,
  logo,
  company,
  tasks,
  badges,
}) => {
  // For even id show card on left side
  // For odd id show card on right side
  const isEvenId = id % 2 === 0;

  const OverlayOne = () => <ModalOverlay backdropFilter="blur(10px)" />;

  const { onOpen } = useDisclosure();
  const [setOverlay] = React.useState(<OverlayOne />);

  return (
    <HStack
      flex={1}
      p={{ base: 3, sm: 6 }}
      spacing={5}
      rounded="lg"
      alignItems="center"
      pos="relative"
    >
      <Box
        onClick={() => {
          setOverlay(<OverlayOne />);
          onOpen();
        }}
        _hover={{
          transform: "scale(1.02)", // or use translate, rotate, etc.
        }}
        transition="transform 0.1s"
      >
        <VStack spacing={2} mb={3} alignItems="flex-start">
          <HStack>
            {logo && (
              <Image src={logo} alt={title} boxSize={50} borderRadius="5" />
            )}
            <chakra.h1
              fontSize="2xl"
              lineHeight={1.2}
              fontWeight="bold"
              w="100%"
            >
              {company}
            </chakra.h1>
          </HStack>
          <Text fontSize="lg" color={isEvenId ? "brand.red" : "brand.red"}>
            {title}
          </Text>
          <Text fontSize="md" color={isEvenId ? "red.200" : "red.200"}>
            {date}
          </Text>
          <Text fontSize="md">{description}</Text>
          <Flex
            direction="row"
            flexWrap="wrap"
            gap={{
              base: "5px",
              sm: "10px",
              md: "20px",
            }}
          >
            {badges.map((badge, index) => (
              <Badge colorScheme="red" key={index}>
                {badge}
              </Badge>
            ))}
          </Flex>
        </VStack>
      </Box>
      {/* <Modal isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <WorkExperienceCard
              {...{ title, description, date, logo, company, tasks }}
            />
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </HStack>
  );
};

const LineWithDot = () => {
  return (
    <Flex
      pos="relative"
      alignItems="center"
      mr={{ base: "20px", md: "40px" }}
      ml={{ base: "0", md: "40px" }}
    >
      <chakra.span
        position="absolute"
        left="50%"
        height="calc(100% + 10px)"
        border="1px solid"
        borderColor={useColorModeValue("gray.200", "gray.700")}
        top="0px"
      ></chakra.span>
      <Box pos="relative" p="10px">
        <Box
          pos="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          width="100%"
          height="100%"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center center"
          bg={useColorModeValue("brand.red", "brand.red")}
          borderRadius="100px"
          backgroundImage="none"
          opacity={1}
        ></Box>
      </Box>
    </Flex>
  );
};

const EmptyCard = () => {
  return (
    <Box
      flex={{ base: 0, md: 1 }}
      p={{ base: 0, md: 6 }}
      bg="transparent"
    ></Box>
  );
};
