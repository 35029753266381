import React from "react";
import {
  Box,
  chakra,
  Flex,
  useBreakpointValue,
  Container,
} from "@chakra-ui/react";
import {
  DiReact,
  DiJavascript1,
  DiHtml5,
  DiCss3,
  DiPython,
  DiGithubBadge,
  DiDocker,
  DiPostgresql,
  DiNodejsSmall,
} from "react-icons/di";
import { SiSolidity, SiChainlink, SiEthereum } from "react-icons/si";

export const Stack = () => {
  const iconSize = useBreakpointValue({ base: "2em", md: "7em" });

  return (
    <Container
      maxW={"70%"}
      p={{ base: 2, sm: 10 }}
      mt={{
        base: 20,
        md: 40,
      }}
    >
      <chakra.h3
        fontSize={{ base: "2xl", sm: "3xl", md: "6xl" }}
        fontWeight="bold"
        textAlign="center"
        mb={{ base: 20, md: 40 }}
      >
        My Tech Stack
      </chakra.h3>
      <Flex
        gap={100}
        flexWrap="wrap"
        align="center"
        p={8}
        alignItems="center"
        justifyContent="center"
      >
        <Box transition="transform 0.2s" _hover={{ transform: "scale(1.2)" }}>
          <DiReact size={iconSize} />
        </Box>
        <Box transition="transform 0.2s" _hover={{ transform: "scale(1.2)" }}>
          <DiJavascript1 size={iconSize} />
        </Box>
        <Box transition="transform 0.2s" _hover={{ transform: "scale(1.2)" }}>
          <DiHtml5 size={iconSize} />
        </Box>
        <Box transition="transform 0.2s" _hover={{ transform: "scale(1.2)" }}>
          <DiCss3 size={iconSize} />
        </Box>
        <Box transition="transform 0.2s" _hover={{ transform: "scale(1.2)" }}>
          <DiPython size={iconSize} />
        </Box>
        <Box transition="transform 0.2s" _hover={{ transform: "scale(1.2)" }}>
          <DiGithubBadge size={iconSize} />
        </Box>
        <Box transition="transform 0.2s" _hover={{ transform: "scale(1.2)" }}>
          <DiDocker size={iconSize} />
        </Box>
        <Box transition="transform 0.2s" _hover={{ transform: "scale(1.2)" }}>
          <DiPostgresql size={iconSize} />
        </Box>
        <Box transition="transform 0.2s" _hover={{ transform: "scale(1.2)" }}>
          <SiSolidity size={iconSize} />
        </Box>
        <Box transition="transform 0.2s" _hover={{ transform: "scale(1.2)" }}>
          <DiNodejsSmall size={iconSize} />
        </Box>
        <Box transition="transform 0.2s" _hover={{ transform: "scale(1.2)" }}>
          <SiChainlink size={iconSize} />
        </Box>
        <Box transition="transform 0.2s" _hover={{ transform: "scale(1.2)" }}>
          <SiEthereum size={iconSize} />
        </Box>
      </Flex>
    </Container>
  );
};
