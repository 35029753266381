import React from "react";
import { chakra, Container, Flex, useBreakpointValue } from "@chakra-ui/react";
import { ProjectCard } from "./ProjectCard";

export const Projects = () => {
  const projects = [
    {
      title: "Top Quality Award",
      company: "Chainlink Hackathon 2022",
      description:
        "I built a tool for Chainlink Node Operators to assist them in debugging jobs on their nodes, ensuring their successful operation. I was awarded the Top Quality Prize Award for my submission.",
      image: "hackathon.png",
      date: "Nov 19, 2022",
      color: "green.500",
    },
    {
      title: "Fair Chance Raffle",
      company: "LinkPool & Chainlink",
      description:
        "I contributed to the development of a raffle dApp that allows users to create and participate in raffles. The dApp is built on the Ethereum blockchain and uses Chainlink VRF to ensure a fair and transparent raffle",
      image: "fairchance.png",
      date: "Feb 19, 2023",
      color: "#375bd2",
    },
    {
      title: "Overtime Market",
      company: "LinkPool & Thales",
      description:
        "I built an adapter for Overtime Markets that allowed them to integrate sports data into their platform on the Optimism network.",
      image: "overtime.png",
      date: "May 19, 2023",
      color: "yellow.400",
    },
    {
      title: "ENS Domain Manager",
      company: "LinkPool",
      description:
        "As part of the LinkPool team, I built a tool for ENS domain owners to manage the renewal of their domains with the use of Chainlink Automation.",
      image: "ens.png",
      date: "Dec 10, 2021",
      color: "blue.400",
    },
    {
      title: "Black Ice Detector",
      company: "Wentworth Institute of Technology",
      description:
        "For the final project of my Embedded Systems class, I built a device that detects black ice on the road and alerts the driver.",
      image: "wit.png",
      date: "Dec 10, 2019",
      color: "red.400",
    },
    {
      title: "NFT Metadata Shuffler",
      company: "LinkPool & Chainlink",
      description:
        "I built a tool that allows NFT creators to shuffle the metadata of their NFTs, ensuring that the metadata is not leaked before the NFT is minted.",
      image: "nft.png",
      date: "Jun 10, 2023",
      color: "purple.400",
    },
    {
      title: "Portfolio Website",
      company: "Personal Project",
      description:
        "I built this website to showcase my projects and skills. I used React and Chakra UI to build the website.",
      image: "hi2.png",
      date: "Feb 18, 2023",
    },
  ];
  const cardWidth = useBreakpointValue({ base: "100%", md: "350px" });

  const direction = useBreakpointValue({ base: "column", md: "row" });

  return (
    <Container
      maxW={"90%"}
      p={{ base: 2, sm: 10 }}
      mt={{
        base: 20,
        md: 40,
      }}
    >
      <chakra.h3
        fontSize={{ base: "2xl", sm: "3xl", md: "6xl" }}
        fontWeight="bold"
        textAlign="center"
        mb={{ base: 20, md: 40 }}
      >
        Awards & Projects
      </chakra.h3>
      <Flex
        gap={20}
        direction={direction}
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        {projects.map((project) => (
          <ProjectCard width={cardWidth} {...project} />
        ))}
      </Flex>
    </Container>
  );
};
