import React from "react";
import {
  chakra,
  Container,
  Flex,
  Image,
  Stack,
  useTheme,
} from "@chakra-ui/react";

export const Bio = () => {
  const theme = useTheme();

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      minH={{
        base: "100vh",
        md: "100vh",
      }}
    >
      <Container maxWidth="7xl" p={{ base: 2, sm: 10 }}>
        <Stack
          textAlign={"center"}
          align={"center"}
          spacing={{ base: theme.sizes[8], md: theme.sizes[10] }}
          py={{ base: theme.sizes[20], md: theme.sizes[28] }}
        >
          <chakra.h3
            fontSize={{ base: "2xl", sm: "3xl", md: "6xl" }}
            fontWeight="bold"
            textAlign="center"
            mb={{ base: 10, md: 30 }}
          >
            My Story
          </chakra.h3>
          <chakra.p
            textAlign="center"
            fontSize={{
              base: "md",
              sm: "lg",
              md: "xl",
            }}
            lineHeight={{
              base: "1.5",
              md: "2",
            }}
          >
            Born in{" "}
            <chakra.span color="brand.red" fontWeight="bold">
              Lebanon
            </chakra.span>{" "}
            🇱🇧, I moved to the
            <chakra.span color="brand.red" fontWeight="bold">
              {" "}
              U.S.
            </chakra.span>{" "}
            🇺🇸 in 2017, seeking new opportunities. My journey has shaped me into
            a{" "}
            <chakra.span color="brand.red" fontWeight="bold">
              versatile developer{" "}
            </chakra.span>
            👨‍💻 and{" "}
            <chakra.span color="brand.red" fontWeight="bold">
              {" "}
              problem solver 🧩{" "}
            </chakra.span>
            . I'm passionate about building products that{" "}
            <chakra.span color="brand.red" fontWeight="bold">
              make a difference{" "}
            </chakra.span>{" "}
            in people's lives. I'm currently{" "}
            <chakra.span color="brand.red" fontWeight="bold">
              based in Boston 🏙️{" "}
            </chakra.span>{" "}
            and working at LinkPool.
          </chakra.p>
          <Image
            src="mac.png"
            maxH={{
              base: "150px",
              md: "300px",
            }}
          />{" "}
        </Stack>
      </Container>
    </Flex>
  );
};
