import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaTwitter, FaLinkedin, FaGithub } from "react-icons/fa";
import { SocialButton } from "../SocialButton/SocialButton";

export const Footer = () => {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction="column"
        spacing={4}
        justify="center"
        align="center"
        textAlign="center"
      >
        <Text>© 2023 Built by Marc Ghannam. All rights reserved</Text>
        <Stack direction={"row"} spacing={6}>
          <SocialButton
            label={"LinkedIn"}
            href={"https://www.linkedin.com/in/marc-g-383509131/"}
          >
            <FaLinkedin />
          </SocialButton>
          <SocialButton
            label={"Twitter"}
            href={"https://twitter.com/MarcGhannam"}
          >
            <FaTwitter />
          </SocialButton>
          <SocialButton
            label={"Github"}
            href={"https://github.com/1marcghannam"}
          >
            <FaGithub />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
};
