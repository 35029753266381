import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  Stack,
  useColorMode,
  Text,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { FaTwitter, FaLinkedin, FaGithub } from "react-icons/fa";
import { SocialButton } from "../SocialButton/SocialButton";

export const NavigationBar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue("gray.100", "gray.900");
  const color = useColorModeValue("black", "white");

  return (
    <Box bg={bg} px={4}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <Text fontSize={{ base: "xl", sm: "2xl", md: "2xl" }} color={color}>
          {"</>"}
        </Text>
        <Stack direction={"row"} spacing={6}>
          <SocialButton
            label={"LinkedIn"}
            href={"https://www.linkedin.com/in/marc-g-383509131/"}
          >
            <FaLinkedin />
          </SocialButton>
          <SocialButton
            label={"Twitter"}
            href={"https://twitter.com/MarcGhannam"}
          >
            <FaTwitter />
          </SocialButton>
          <SocialButton
            label={"Github"}
            href={"https://github.com/1marcghannam"}
          >
            <FaGithub />
          </SocialButton>
        </Stack>
        <Flex alignItems={"center"}>
          <Stack direction={"row"} spacing={7}>
            <Button onClick={toggleColorMode} bgColor={"whiteAlpha.200"}>
              {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};
