import React, { useRef } from "react";
import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
  useTheme,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Link,
  Image,
} from "@chakra-ui/react";
import { Bio } from "./Bio";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";

export const Hero = () => {
  const theme = useTheme();
  const bioRef = useRef(null);

  const scrollToBio = () => {
    bioRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        minH="100vh"
      >
        <Container maxW={"5xl"}>
          <Stack
            textAlign={"center"}
            align={"center"}
            spacing={{ base: theme.sizes[8], md: theme.sizes[10] }}
            py={{ base: theme.sizes[20], md: theme.sizes[28] }}
          >
            <Image
              src="hi.png"
              maxH={{
                base: "200px",
                md: "300px",
              }}
            />
            <Heading as="h1" fontSize={{ base: "4xl", sm: "6xl", md: "8xl" }}>
              Hello, I'm{" "}
              <Text as={"span"} color={"brand.red"}>
                Marc
              </Text>
            </Heading>
            <Text
              fontSize={{
                base: "md",
                sm: "lg",
                md: "xl",
              }}
            >
              I'm a Full Stack Engineer turning complex ideas into reality.
              Welcome to my journey through the digital landscape. Enjoy
              exploring!
            </Text>
            <Stack spacing={6} direction={"row"}>
              <Button bgColor={"brand.red"} px={6} onClick={scrollToBio}>
                Learn more
              </Button>
              <Popover>
                <PopoverTrigger>
                  <div>
                    <Button px={6} bgColor="gray.400">
                      Contact me
                    </Button>{" "}
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverHeader>
                    I look forward to hearing from you!
                  </PopoverHeader>
                  <PopoverBody>
                    <Flex alignItems="center" mb="2">
                      <EmailIcon boxSize="5" mr="2" />
                      <Link
                        href="mailto:1marc.ghannam@gmail.com"
                        color="brand.red"
                      >
                        1marc.ghannam@gmail.com
                      </Link>
                    </Flex>
                    <Flex alignItems="center">
                      <PhoneIcon boxSize="5" mr="2" />
                      <Link href="tel:+17815322591" color="brand.red">
                        +1 (781) 532 - 2591
                      </Link>
                    </Flex>{" "}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Stack>
          </Stack>
        </Container>
      </Flex>
      <div ref={bioRef}>
        <Bio />
      </div>
    </>
  );
};
