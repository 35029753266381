import { NavigationBar } from "./components/NavigationBar/NavigationBar";
import { Hero } from "./components/Hero/Hero";
import { Footer } from "./components/Footer/Footer";
import { WorkExperience } from "./components/WorkExperience/WorkExperience";
import { Projects } from "./components/Projects/Projects";
import { Stack } from "./components/Stack/Stack";
import { Fin } from "./components/Fin/Fin";

function App() {
  return (
    <>
      <NavigationBar />
      <Hero />
      <WorkExperience />
      <Projects />
      <Stack />
      <Fin />
      <Footer />
    </>
  );
}

export default App;
